import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
} from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = ({ direction, ...args }) => {
  
  const [data, setData] = useState({ name: "", email: ""  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });

    console.log(name, "data");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://example.com/api/endpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setResponseMessage(result.message || "Data submitted successfully!");
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("Failed to submit data");
    }
  };
  return (
    <Helmet title="Contact">
      <CommonSection title="Delete Account" />
      <section>
        <Container>
        <div className="account-deletion-container">
      <h1>How to Delete Your Account</h1>
      {/* <img 
        src="path_to_your_image.png" // Replace with the actual image path
        alt="Account Deletion Instructions"
        className="instruction-image"
      /> */}
      <ol className="instruction-list">
        <li>
          <strong>Log In to Your Account</strong>
          <p>Go to the Menu</p>
        </li>
        <li>
          <strong>Access Account Settings</strong>
          <p>Click on your menu icon in the upper left corner. Select "Account Settings" from the  menu.</p>
        </li>
        <li>
          <strong>Navigate to Account Deletion</strong>
          <p>In the Account Settings menu, find and click on the "Delete Account".</p>
        </li>
        <li>
          <strong>Confirm Deletion</strong>
          <p>Read the information provided about account deletion. Click on the "Delete Account" button. You may be prompted to comfirm Delete Account.</p>
        </li>
     
        {/* <li>
          <strong>Final Confirmation</strong>
          <p>Your account will be permanently deleted.</p>
        </li> */}
      </ol>
      <div className="important-notes">
        <h2>Note:</h2>
        <ul>
          <li>After Deleting Your account and Data will be permanently deleted.</li>
          <li>If you face any issues, please contact our support team at contact@oncabglobal.com.</li>
        </ul>
      </div>
    </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
